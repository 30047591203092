import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import withPopup from '../../../HOC/withPopup';
import EditTerminationsPopup from './EditTerminationsPopup';
import {requestImportsCompanyConfig} from '../../../actions/adminPortal';
import {requestFeatureFlag} from '../../../actions/general';
import {showToast} from '../../../actions/shared';
import {getProfileInfo} from '../../../selectors/general';
import {FEATURE_FLAGS} from '../../../constants';
import './index.scss';

const POPUP_ID = 'CustomTerminationsThresholdsPopup';

const CustomTerminationsThresholds = ({groupAlias, openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const {email: userEmail} = useSelector(getProfileInfo);
    const [companyConfig, setCompanyConfig] = useState({});
    const [featureFlags, setFeatureFlags] = useState({isTermination: false, isRenewal: false});

    useEffect(() => {
        (async () => {
            const [
                {data: companyConfig},
                {flag: isTermination},
                {flag: isRenewal}
            ] = await Promise.all([
                dispatch(requestImportsCompanyConfig(groupAlias)),
                dispatch(requestFeatureFlag(FEATURE_FLAGS.terminationSurgeVisibility, {userEmail})),
                dispatch(requestFeatureFlag(FEATURE_FLAGS.customRenewalThresholds, {userEmail}))
            ]);

            setCompanyConfig(companyConfig);
            setFeatureFlags({isTermination, isRenewal});
        })();
    }, [dispatch, groupAlias, userEmail]);

    const onEditThresholdsSuccess = companyConfig => {
        setCompanyConfig(companyConfig);
        dispatch(showToast({content: 'Terminations threshold changes saved!'}));
    };

    const onEditThresholds = () => openPopup({type: POPUP_TYPES.simple, children: <EditTerminationsPopup groupAlias={groupAlias} companyConfig={companyConfig} onSuccess={onEditThresholdsSuccess} closePopup={closePopup} isRenewal={featureFlags?.isRenewal}/>});

    return featureFlags?.isTermination && (
        <ContentSection className='custom-terminations-thresholds'>
            <Row middle='xs' between='xs'>
                <Column sm={4}>
                    <Text type={TEXT_TYPES.helper} className='mb-2'>Terminations</Text>
                    <Text>Launch: {companyConfig?.custom_termination_threshold?.new_launch}%</Text>
                    <Text>Maintenance: {companyConfig?.custom_termination_threshold?.maintenance}%</Text>
                </Column>
                {featureFlags?.isRenewal && (
                    <Column sm={4}>
                        <Text>Renewal: {companyConfig?.custom_termination_threshold?.renewal}%</Text>
                    </Column>
                )}
                <Column constant>
                    <Button type={BUTTON_TYPES.tertiary} onClick={onEditThresholds}>Edit Thresholds</Button>
                </Column>
            </Row>
        </ContentSection>
    );
};

CustomTerminationsThresholds.propTypes = {
    groupAlias: PropTypes.string,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {CustomTerminationsThresholds as TestableCustomTerminationsThresholds};
export default withPopup(POPUP_ID)(CustomTerminationsThresholds);
