import {
    START_LOADING,
    FINISH_LOADING,
    OPEN_POPUP,
    CLOSE_POPUP,
    SHOW_TOAST,
    HIDE_TOAST,
    SET_WIZARD_ACTIVE_STEP,
    GO_NEXT_WIZARD_STEP,
    GO_PREV_WIZARD_STEP,
    RECEIVE_CORE_CARRIERS,
    RECEIVE_ANCILLARY_CARRIERS,
    SET_DIRTY_FORMS,
    RECEIVE_FORM_REVISIONS,
    CLEAR_FORM_REVISIONS
} from '../actions/actionTypes';

const DEFAULT_TOAST_VISIBILITY_TIME = 5000;

export const initialState = {
    loader: {
        isLoader: false,
        loadingCount: 0
    },
    toast: {
        isVisible: false,
        content: '',
        visibilityTime: DEFAULT_TOAST_VISIBILITY_TIME
    },
    popup: {}, // FYI: popup structure - {[id]: {isOpened, id, type, children}} (22.03.2021, Vlad);
    wizard: {activeStep: 0},
    coreCarriers: [],
    ancillaryCarriers: [],
    dirtyForms: [], // FYI: final-form dirty forms. (12.11.21, Oleh)
    formRevisions: {} // FYI: formRevisions structure - {originalRevision: {}, comparisonRevisions: []} (23.12.21, Oleh)
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case START_LOADING: {
            const isLoader = state.loader.isLoader || payload.isLoader; // FYI: checking for pending request with loader (04.08.20, Vlad)
            const loadingCount = state.loader.loadingCount + 1;
            const loader = {isLoader, loadingCount};

            return {...state, loader};
        }

        case FINISH_LOADING: {
            const loadingCount = state.loader.loadingCount - 1;
            const isLoader = state.loader.isLoader && !!loadingCount; // FYI: checking for request without loader (13.04.20, Oleh)
            const loader = {isLoader, loadingCount};

            return {...state, loader};
        }

        case OPEN_POPUP: {
            const {params: {id, ...params}} = payload;
            const popup = {...state.popup, [id]: {...params, isOpened: true}};

            return {...state, popup};
        }

        case CLOSE_POPUP: {
            const {id} = payload;
            const popup = {...state.popup, [id]: {}};

            return {...state, popup};
        }

        case SHOW_TOAST: {
            const {content, visibilityTime = DEFAULT_TOAST_VISIBILITY_TIME} = payload;
            const toast = {content, visibilityTime, isVisible: true};

            return {...state, toast};
        }

        case HIDE_TOAST: {
            return {...state, toast: initialState.toast};
        }

        case SET_WIZARD_ACTIVE_STEP:
        case GO_NEXT_WIZARD_STEP:
        case GO_PREV_WIZARD_STEP: {
            const {activeStep} = payload;
            const wizard = {activeStep};

            return {...state, wizard};
        }

        case RECEIVE_CORE_CARRIERS: {
            const {coreCarriers} = payload;

            return {...state, coreCarriers};
        }

        case RECEIVE_ANCILLARY_CARRIERS: {
            const {ancillaryCarriers} = payload;

            return {...state, ancillaryCarriers};
        }

        case SET_DIRTY_FORMS: {
            const {dirtyForms} = payload;

            return {...state, dirtyForms};
        }

        case RECEIVE_FORM_REVISIONS: {
            const {formRevisions} = payload;

            return {...state, formRevisions};
        }

        case CLEAR_FORM_REVISIONS: {
            return {...state, formRevisions: {}};
        }

        default:
            return state;
    }
};
