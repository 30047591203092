import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter, useLocation} from 'react-router-dom';
import {redirectTo} from '../../actions/general';
import {getActiveCompany, getTimelineExistenceStatus, getRoutesUnavailabilityMap, getProfileInfo} from '../../selectors/general';
import {compose, equal, negate, parseQuery} from '../../utils';
import {ROUTES, ZENDESK_HELP_CENTER_URL, USER_ROLES} from '../../constants';

const HELPCENTER_REDIRECTION_PAGE = `${ROUTES.externalRedirection}?redirect_to=${ZENDESK_HELP_CENTER_URL}`;
const ZENDESK_LINKS = {
    zendesk: 'zendesk',
    helpCenter: 'help-center',
    helpCenterComEnv: 'healthjoyhelp1712057866'
};

const withRedirectionStrategy = Component => {
    const WithRedirectionStrategy = props => {
        const {path} = props;
        const [isReady, setIsReady] = useState(false);
        const dispatch = useDispatch();
        const activeCompany = useSelector(getActiveCompany);
        const isTimelineExisted = useSelector(getTimelineExistenceStatus);
        const routesUnavailabilityMap = useSelector(getRoutesUnavailabilityMap);
        const {role_per_company: rolePerCompany} = useSelector(getProfileInfo);
        const {pathname, search} = useLocation();
        const currentPage = `${pathname}${search}`;
        const {return_to: returnTo} = parseQuery(search);
        const isZendesk = Object.values(ZENDESK_LINKS).some(link => returnTo?.includes(link));

        const getReserveRoute = () => {
            const {isUnavailable, reserveRoute} = routesUnavailabilityMap[path] || {};
            const isAdminPortalPage = path.startsWith(ROUTES.adminPortalRoot);

            let route = isUnavailable && reserveRoute;
            // FYI: we compare with path due to possibility of using dynamic routes (/:id, /:id?, /:type, etc);
            if (equal(isTimelineExisted, false) && negate(equal(path, ROUTES.timelineGeneration)) && !isAdminPortalPage) {
                route = ROUTES.timelineGeneration;
            }

            return route;
        };

        useEffect(() => {
            const reserveRoute = getReserveRoute();

            // FYI: We need this redirect for users who has Help "Center-Only Viewer" role,
            // and will not be able to access any other part of HealthJoy’s client dashboard. (Seva 21.08.24)
            if (!equal(currentPage, HELPCENTER_REDIRECTION_PAGE) && rolePerCompany && Object.values(rolePerCompany).includes(USER_ROLES.zendesk)) {
                return dispatch(redirectTo(HELPCENTER_REDIRECTION_PAGE));
            }

            if (returnTo && isZendesk) {
                return dispatch(redirectTo(`${ROUTES.externalRedirection}?redirect_to=${returnTo}`));
            }

            if (reserveRoute) {
                return dispatch(redirectTo(reserveRoute));
            }

            setIsReady(true);
        }, [dispatch, returnTo, isZendesk, path, activeCompany, isTimelineExisted, routesUnavailabilityMap, rolePerCompany]);

        const componentProps = {
            ...props,
            redirectTo: route => dispatch(redirectTo(route)),
            activeCompany,
            isTimelineExisted,
            routesUnavailabilityMap
        };

        return isReady && <Component {...componentProps}/>;
    };

    WithRedirectionStrategy.propTypes = {
        path: PropTypes.string
    };

    return WithRedirectionStrategy;
};

export {withRedirectionStrategy as testableWithRedirectionStrategy};
export default compose(
    withRouter,
    withRedirectionStrategy
);
