import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import CategoryFormContent from './CategoryFormContent';
import PopupActionBar from './PopupActionBar';
import Category from './Category';
import {Field, Form} from '../../../shared/FormComponents';
import {
    requestIndividualConfigAncillaryCategoryCreating,
    requestUnlistedAncillaryCategories
} from '../../../../actions/adminPortal';
import {showToast} from '../../../../actions/shared';
import {equal, isEmpty, validateRequired} from '../../../../utils';
import {FORMS} from '../../../../constants';

const DEFAULT_PLANS_LENGTH = 6;

/* istanbul ignore next */
const validate = (values, isCreating) => {
    return {
        ...isCreating && {category: validateRequired(values.category)}
    };
};

const CATEGORY_INITIAL_VALUES = {
    category: '',
    coverage_tier: [],
    effective_date: [],
    expiration_date: [],
    group_number: [],
    name: [],
    policy_number: [],
    policy_amount: []
};

const AddCategoryPopup = ({getFormattedCategory, closePopup, importConfigId}) => {
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const [unlistedCategories, setUnlistedCategories] = useState([]);
    const [defaultPlanLength, setDefaultPlanLength] = useState(DEFAULT_PLANS_LENGTH);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const isShowLess = defaultPlanLength >= unlistedCategories.length;
    const [initialValues, setInitialValues] = useState(CATEGORY_INITIAL_VALUES);

    const onCategoryClick = category => setSelectedCategory(selectedCategory => equal(selectedCategory, category) ? null : category);

    const setUnlistedAncillaryCategories = async () => {
        const {data: unlistedCategories} = await dispatch(requestUnlistedAncillaryCategories(importConfigId));

        setUnlistedCategories(unlistedCategories);
    };

    useEffect(() => {
        setUnlistedAncillaryCategories();
    }, []);

    const getToastContent = text => `Ancillary Plan - ${text} was added!`;

    const onSubmit = async ({isClosePopup, toastText: _, ...values}) => {
        // FYI: related to business logic FE should create selected default category and form at the same time (Pasha, 06.14.2024)
        const requestsData = [values, {category: selectedCategory}]
            .map(({category, ...values}) => category && {configId: importConfigId, categoryDetails: {category, ...values}})
            .filter(Boolean);
        const [
            {isSuccess: formPlanIsSuccess, data: formPlanData, submissionErrors} = {},
            {data: defaultPlanData} = {}
        ] = await Promise.all(requestsData.map(data => dispatch(requestIndividualConfigAncillaryCategoryCreating(data))));

        setSelectedCategory(null);
        setUnlistedAncillaryCategories();

        if (!formPlanIsSuccess && submissionErrors) {
            if (defaultPlanData) {
                dispatch(showToast({content: getToastContent(defaultPlanData?.category)}));
            }

            return submissionErrors;
        }

        setInitialValues({...(formPlanData || defaultPlanData), isClosePopup});
    };

    const onSubmitSuccess = ({values}) => {
        if (values?.isClosePopup) {
            closePopup();
        }

        setIsCreating(false);
        setInitialValues(CATEGORY_INITIAL_VALUES);
        dispatch(showToast({content: getToastContent(values?.toastText)}));
    };

    return (
        <Form initialValues={initialValues} validate={values => validate(values, isCreating)} name={FORMS.importConfigAncillary} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit, form, values}) => {
                const onSave = isClosePopup => {
                    const toastText = [values?.category, selectedCategory].filter(Boolean).join(', ');

                    form.batch(() => {
                        form.change('toastText', toastText);
                        form.change('isClosePopup', isClosePopup);
                    });
                    handleSubmit();
                };

                const actionBarProps = {
                    onCancel: closePopup,
                    ...isCreating && {onSaveAndContinue: () => onSave(false)},
                    onSaveAndClose: () => onSave(true)
                };

                const popupProps = {
                    title: 'Add Ancillary Plan',
                    actionBar: <PopupActionBar {...actionBarProps}/>,
                    isScrollableContent: true
                };

                return (
                    <PopupContent {...popupProps}>
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='add-ancillary-category'>
                                <Text className='mb-6'><Text isInline type={TEXT_TYPES.bodyBold}>Choose from the Default Plans</Text> that have not yet been used for this company:</Text>

                                {isEmpty(unlistedCategories) ? (
                                    <Category>
                                        <div className='no-default-plans'>
                                            <Icon type={ICON_TYPES.ancillary} className='mr-5'/> All Default Plans have already been added to this company.
                                        </div>
                                    </Category>
                                ) : (
                                    <Row rowGap='sm'>
                                        {unlistedCategories.slice(0, defaultPlanLength).map(category => (
                                            <Column xs={6} key={category}>
                                                <Category hasIcon key={category} isSelected={equal(selectedCategory, category)} onClick={() => onCategoryClick(category)}>
                                                    {getFormattedCategory(category)}
                                                </Category>
                                            </Column>
                                        ))}
                                    </Row>
                                )}

                                {(unlistedCategories.length > DEFAULT_PLANS_LENGTH) && (
                                    <Row end='xs' className='mt-5'>
                                        <Column>
                                            <Button type={BUTTON_TYPES.tertiary} onClick={() => setDefaultPlanLength(isShowLess ? DEFAULT_PLANS_LENGTH : defaultPlanLength + DEFAULT_PLANS_LENGTH)}>
                                                <Text type={TEXT_TYPES.bodyBold} isInline className='mr-2'>{isShowLess ? 'Show less' : 'Show more'}</Text> default
                                                templates
                                            </Button>
                                        </Column>
                                    </Row>
                                )}

                                <Text className='mt-20 mb-6'><Text isInline type={TEXT_TYPES.bodyBold}>Create a new Ancillary Plan</Text> if it doesn’t exist among the Default Plans:</Text>

                                {isCreating ? (
                                    <div className='new-plan'>
                                        <Row className='mb-5' middle='xs'>
                                            <Column sm>
                                                <Text>New Ancillary Plan</Text>
                                            </Column>
                                            <Column constant>
                                                <Button type={BUTTON_TYPES.tertiary} onClick={() => setIsCreating(false)}>
                                                    <Icon className='delete-icon' type={ICON_TYPES.delete}/>
                                                </Button>
                                            </Column>
                                        </Row>

                                        <Field name='category'>
                                            {props => <Input {...props} label='Category' isRequired wrapperClassName='mb-10'/>}
                                        </Field>

                                        <CategoryFormContent/>
                                    </div>
                                ) : (
                                    <Category hasIcon onClick={() => setIsCreating(true)}>Create New Ancillary Plan</Category>
                                )}
                            </div>
                        </form>
                    </PopupContent>
                );
            }}
        </Form>
    );
};

AddCategoryPopup.propTypes = {
    importConfigId: PropTypes.number,
    getFormattedCategory: PropTypes.func,
    closePopup: PropTypes.func
};

export default AddCategoryPopup;
