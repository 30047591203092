import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Heading from '@frontend/ui-kit/Components/Heading';
import Table, {ROW_HIGHLIGHT_TYPES} from '@frontend/ui-kit/Components/Table';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text from '@frontend/ui-kit/Components/Text';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Link from '@frontend/ui-kit/Components/Link';
import MultipleChoice from '../MultipleChoice';
import LoadingBar from '../../shared/LoadingBar';
import {
    clearMultipleChoice,
    requestEligibilityImportSessions,
    requestEligibilityImportUsers,
    requestImportsJsonrpc,
    requestTPAPartnerImportSessions
} from '../../../actions/adminPortal';
import {redirectTo, requestCompanies, requestFeatureFlag, requestTPAPartner} from '../../../actions/general';
import {getProfileInfo} from '../../../selectors/general';
import {getIsImportSessionThresholds} from '../../../helpers';
import {
    convertDateToTimeZone,
    equal,
    formatDate,
    generateUniqueId,
    getItemKeyValue,
    isEmpty,
    isNumber,
    isUrl,
    parseQuery,
    promisifyAsyncFunction,
    toCapitalize
} from '../../../utils';
import {
    AUTOIMPORT_CREATED_BY,
    ELIGIBILITY_IMPORT_STATUSES,
    FEATURE_FLAGS,
    ROUTES,
    TPA_PARTNER_IMPORT_SESSION_STATUSES
} from '../../../constants';
import './index.scss';

const INTERVAL_DELAY = 10000;

const TABLE_PAGE_SIZE = 20;

const AUTO_IMPORT_RESTARTED_CANCELED_REASON = 'Import was aborted by job monitor due to timeout.';

const AUTO_IMPORT_INVESTIGATION_CANCELED_REASON = 'The ENG team is investigating the issue.';

const EligibilityImportsSearch = ({partnerId, groupAlias, setImportsData, isTitleVisible, getLastSession}) => {
    const {email: userEmail} = useSelector(getProfileInfo);
    const [isVerificationReportUI, setIsVerificationReportUI] = useState(false);
    const [table, setTable] = useState({unitCount: 0, pageIndex: 0});
    const [importData, setImportData] = useState([]);
    const [importSearch, setImportSearch] = useState({});
    const dispatch = useDispatch();
    const timeoutIdRef = useRef(null);
    const {search} = useHistory().location;
    const {partner_name: partnerName} = parseQuery(search);
    const [rowProps, setRowProps] = useState({});

    useEffect(() => {
        (async () => {
            const {flag: isVerificationReportUI, isSuccess} = await dispatch(requestFeatureFlag(FEATURE_FLAGS.showVerificationReportUI, {userEmail}));

            if (!isSuccess) {
                return false;
            }

            setIsVerificationReportUI(isVerificationReportUI);
        })();

        return () => {
            dispatch(clearMultipleChoice());

            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [userEmail]);

    const loadCompanyAliasOptions = promisifyAsyncFunction(async query => {
        const {companies} = await dispatch(requestCompanies(query));

        return companies.map(({alias}) => ({label: alias, value: alias}));
    });

    const loadUploadedByOptions = promisifyAsyncFunction(async query => {
        const {usersList} = await dispatch(requestEligibilityImportUsers(query));

        return usersList.map(user => ({label: user, value: user}));
    });

    const getTPATableData = data => {
        return data.map(({groups_individual_import_sessions: subRows, ...tableRow}) => ({...tableRow, ...subRows.length && {subRows}}));
    };

    const getImportsTableData = data => {
        return data.map(({references: subRows, ...tableRow}) => ({...tableRow, ...subRows && {subRows}}));
    };

    const getIsAutoImportRestartedReason = reason => [AUTO_IMPORT_INVESTIGATION_CANCELED_REASON, AUTO_IMPORT_RESTARTED_CANCELED_REASON].some(canceledReason => equal(canceledReason, reason));

    const getIsAutoImportRestarted = ({additional_data: additionalData, status}) => equal(additionalData?.created_by, AUTOIMPORT_CREATED_BY)
        && equal(status, ELIGIBILITY_IMPORT_STATUSES.cancelled)
        && getIsAutoImportRestartedReason(additionalData?.canceled_reason);

    const getRowProps = data => {
        return data?.reduce((acc, session) => {
            const {id: sessionId, subRows: references, additional_data: additionalData, status} = session || {};
            const isAutoimportComplete = equal(additionalData?.created_by, AUTOIMPORT_CREATED_BY) && equal(status, ELIGIBILITY_IMPORT_STATUSES.success);
            const referencedSessionsProps = !isAutoimportComplete && !isEmpty(references)
                ? references.reduce((acc, session) => ({...acc, ...getIsAutoImportRestarted(session) ? {[session?.id]: {highlightType: ROW_HIGHLIGHT_TYPES.danger}} : {}}), {})
                : {};

            return {...acc, ...referencedSessionsProps, ...getIsAutoImportRestarted(session) ? {[sessionId]: {highlightType: ROW_HIGHLIGHT_TYPES.danger}} : {}};
        }, {});
    };

    const onFetchData = useCallback(async ({pageIndex, ...params}) => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }

        const requestImportSession = partnerId ? requestTPAPartnerImportSessions : requestEligibilityImportSessions;
        const {sessions, count, isSuccess} = await dispatch(requestImportSession(params));

        if (!isSuccess) {
            return false;
        }

        const enhancedSessions = partnerId ? getTPATableData(sessions?.data) : getImportsTableData(sessions);

        if (getLastSession && equal(pageIndex, 0)) {
            getLastSession(enhancedSessions[0]);
        }

        setImportsData(enhancedSessions, params);
        setImportData(enhancedSessions);
        setRowProps(getRowProps(enhancedSessions));
        setTable({unitCount: count || sessions?.count, pageIndex});
        timeoutIdRef.current = setTimeout(() => onFetchData(params), INTERVAL_DELAY);
    }, [dispatch, partnerId]);

    const onFetchTableData = useCallback(({pageIndex = 0, pageSize = TABLE_PAGE_SIZE} = {}) => {
        const params = {
            pageIndex,
            limit: pageSize,
            offset: pageIndex * pageSize,
            ...groupAlias ? {...importSearch, company_alias: groupAlias} : importSearch,
            ...partnerId ? {tpa_partner_id: partnerId, only_active: false} : {}
        };

        onFetchData(params);
    }, [dispatch, onFetchData, importSearch, partnerId]);

    const getTPAPartner = async partnerId => {
        /* FYI: There is no partner name in the Import session company_data due to not syncing different databases.
           So we should add a request for getting the correct partner name.

           Don't forget to remove it and switch to the partner name from the Import session company_data after synchronization databases. */
        const {partner, isSuccess} = await dispatch(requestTPAPartner(partnerId));

        if (!isSuccess) {
            return false;
        }

        return partner;
    };

    const getTableColumns = () => {
        const getDate = ({value: date}) => `${formatDate(convertDateToTimeZone(date), 'MM/dd/yyyy H:mm')} CST`;

        const getUploadedBy = ({value: uploadedBy}) => {
            if (equal(uploadedBy, AUTOIMPORT_CREATED_BY)) {
                return <Text>System</Text>;
            }

            const uploadedByAbbr = uploadedBy && uploadedBy.split(' ').map(str => str.charAt(0).toUpperCase()).join('');
            return <Tooltip content={uploadedBy}><div className='uploaded-by'>{uploadedByAbbr}</div></Tooltip>;
        };

        const getType = ({value: type, cell}) => {
            const {is_cobra: isCobra, additional_data: additionalData} = cell?.row?.original || {};
            const isAutoimport = equal(additionalData?.created_by, AUTOIMPORT_CREATED_BY);
            const labelByType = {
                'New Launch': 'Launch'
            }[type] || type;
            const label = isAutoimport ? 'Automated' : labelByType;

            return (type || isAutoimport) ? <Sticker className='type' type={STICKER_TYPES.default}>{isCobra ? 'COBRA - ' : ''}{label}</Sticker> : '';
        };

        const getStatus = ({value: status, cell}) => {
            const {id, company_alias: companyAlias, additional_data: additionalData, company_data: companyData} = cell?.row?.original || {};
            const isAutoimport = equal(additionalData?.created_by, AUTOIMPORT_CREATED_BY);

            const onClickValidationFileButton = async () => {
                const {tpa_partner_id: partnerId} = companyData || {};
                const getValidateFileTpaRoute = async () => {
                    const {tpa_name: partnerName} = await getTPAPartner(partnerId);
                    return `${ROUTES.importsTpa}/${partnerId}/validation_report/${id}?partner_name=${encodeURIComponent(partnerName)}`;
                };

                const route = partnerId
                    ? await getValidateFileTpaRoute()
                    : `${ROUTES.importsIndividual}/${companyAlias}/validation_report/${id}?group_name=${encodeURIComponent(additionalData?.company_name)}${!isEmpty(cell?.row?.originalSubRows) ? '&isAutoImport=true' : ''}`;
                return route && dispatch(redirectTo(route));
            };

            const onClickVerificationReportButton = async () => {
                const {tpa_partner_id: partnerId} = companyData || {};
                const getVerificationReportTpaRoute = async () => {
                    const {tpa_name: partnerName} = await getTPAPartner(partnerId);
                    return `${ROUTES.importsTpa}/${partnerId}/verification_report/${id}?partner_name=${encodeURIComponent(partnerName)}`;
                };

                const route = partnerId
                    ? await getVerificationReportTpaRoute(partnerId)
                    : `${ROUTES.importsIndividual}/${companyAlias}/verification_report/${id}?group_name=${encodeURIComponent(additionalData?.company_name)}`;
                return route && dispatch(redirectTo(route));
            };

            const onRetryVerificationReport = async () => {
                const jsonrpcObj = {jsonrpc: '2.0', method: 'rerun_verification_report', id: generateUniqueId(), params: {session: cell?.row?.original}};
                const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

                if (!equal(jsonrpc?.result, 'ok')) {
                    return false;
                }

                onFetchTableData({pageIndex: table.pageIndex});
            };

            const onGoToPreProcessingReport = () => dispatch(redirectTo(`${ROUTES.importsTpa}/${partnerId}/pre_processing_report/${id}?partner_name=${encodeURIComponent(partnerName)}`));

            const getFailedStatus = () => (
                <div className='status status_error'>
                    <Tooltip content={<span className='import-status-tooltip-content'>{cell?.row?.original?.failed_reason}</span>}><Icon className='mr-3' type={ICON_TYPES.info}/></Tooltip>
                    <div className='status__content'>Failed</div>
                </div>
            );

            if (cell?.row?.original?.import_configs_tweaks && equal(status, TPA_PARTNER_IMPORT_SESSION_STATUSES.failed)) {
                return getFailedStatus();
            }

            if (cell?.row?.original?.preprocessing_report && !equal(status, TPA_PARTNER_IMPORT_SESSION_STATUSES.canceled)) {
                return (
                    <div className='status status_pre-processing-report'>
                        <Icon type={ICON_TYPES.implChecklist}/>
                        <div className='status__content'>
                            <Button data-testid='pre-processing-report-button' onClick={onGoToPreProcessingReport} type={BUTTON_TYPES.tertiary} className='status__button'>Pre-processing Report</Button>
                        </div>
                    </div>
                );
            }

            const verificationReportUrl = cell?.row?.original?.additional_data?.verification_report_url;

            const isReportFailed = !isVerificationReportUI && (!verificationReportUrl || equal(verificationReportUrl, 'verification service failed')) && equal(status, TPA_PARTNER_IMPORT_SESSION_STATUSES.success);

            if (isReportFailed) {
                return (
                    <div className='status status_error'>
                        <Tooltip content='Verification service was unavailable at the time of request. Please re-try your request at a later time.'><Icon type={ICON_TYPES.cancelCircle}/></Tooltip>
                        <div className='status__content'>
                            <Text>Server Error.  <Button data-testid='pre-processing-report-button' onClick={onRetryVerificationReport} type={BUTTON_TYPES.tertiary} className='status__button'>Retry</Button></Text>
                            <Button data-testid='validation-report-button' onClick={onClickValidationFileButton} type={BUTTON_TYPES.tertiary} className='status__button status__button_validation-file'>View Validation Report</Button>
                        </div>
                    </div>
                );
            }

            if (equal(status, ELIGIBILITY_IMPORT_STATUSES.success) && !isEmpty(cell?.row?.originalSubRows)) {
                const lastReferencedSession = cell?.row?.originalSubRows[0] || {};
                const isBlockersResolved = !cell?.row?.original?.has_blockers && cell?.row?.originalSubRows.some(getItemKeyValue('has_blockers'));
                const isThresholdsResolved = getIsImportSessionThresholds(cell?.row?.original) || cell?.row?.originalSubRows.some(getIsImportSessionThresholds);
                const isLastSessionRestarted = getIsAutoImportRestarted(lastReferencedSession);

                return (
                    <div className='status status_view-report'>
                        <Icon type={ICON_TYPES.checkCircleFull}/>
                        <div className='status__content'>
                            {isLastSessionRestarted && <Text className='import-resolved'>Auto Import Complete</Text>}
                            {!isLastSessionRestarted && isBlockersResolved && !isThresholdsResolved && <Text className='import-resolved'>Blockers Resolved</Text>}
                            {!isLastSessionRestarted && isThresholdsResolved && !isBlockersResolved && <Text className='import-resolved'>Thresholds Resolved</Text>}
                            <Button data-testid='validation-report-button' onClick={onClickValidationFileButton} type={BUTTON_TYPES.tertiary} className='status__button'>View Validation Report</Button>
                        </div>
                    </div>
                );
            }

            if (isAutoimport && equal(status, ELIGIBILITY_IMPORT_STATUSES.cancelled) && equal(additionalData?.canceled_reason, AUTO_IMPORT_INVESTIGATION_CANCELED_REASON)) {
                return (
                    <Text className='status status_verification-report'>{AUTO_IMPORT_INVESTIGATION_CANCELED_REASON}</Text>
                );
            }

            if (isAutoimport && equal(status, ELIGIBILITY_IMPORT_STATUSES.cancelled) && equal(additionalData?.canceled_reason, AUTO_IMPORT_RESTARTED_CANCELED_REASON)) {
                const tooltipContent = 'Import restarted because it did not complete within the expected timeframe for this group size. System will attempt to restart the import over a 12-hour period. If the issue persists beyond this period, the ENG team will automatically be alerted to investigate.';

                return (
                    <div className='status status_verification-report'>
                        <Tooltip content={tooltipContent}><Icon type={ICON_TYPES.info}/></Tooltip>
                        <div className='status__content'>
                            <Text className='status_verification-report'>Import Restarted</Text>
                        </div>
                    </div>
                );
            }

            const contentByStatus = {
                [ELIGIBILITY_IMPORT_STATUSES.new]: <Text>Pending</Text>,
                [TPA_PARTNER_IMPORT_SESSION_STATUSES.preprocessing]: <Text>Pre-processing</Text>,
                [ELIGIBILITY_IMPORT_STATUSES.processing]: <LoadingBar className='loading-bar' progress={cell?.row?.original?.progress}/>,
                [ELIGIBILITY_IMPORT_STATUSES.failed]: getFailedStatus(),
                [ELIGIBILITY_IMPORT_STATUSES.pending_confirmation]: (
                    <div className='status status_validate-file'>
                        <Icon type={ICON_TYPES.eligibility}/>
                        <div className='status__content'>
                            <Button data-testid='validation-report-button' onClick={onClickValidationFileButton} type={BUTTON_TYPES.tertiary} className='status__button'>Validate File</Button>
                        </div>
                    </div>
                ),
                [ELIGIBILITY_IMPORT_STATUSES.success]: (
                    <div className='status status_view-report'>
                        <Icon type={ICON_TYPES.checkCircleFull}/>
                        <div className='status__content'>
                            {getIsImportSessionThresholds(cell?.row?.original) && <Text className='import-resolved'>Thresholds Resolved</Text>}
                            <Button data-testid='validation-report-button' onClick={onClickValidationFileButton} type={BUTTON_TYPES.tertiary} className='status__button'>View Validation Report</Button>
                            {/* FYI: Verification report is not generated for auto-imports. So this link should be hidden. (Pasha, 07.25.2024) */}
                            {!isAutoimport && (
                                isVerificationReportUI
                                    ? <Button data-testid='verification-report-button' className='status__button' onClick={onClickVerificationReportButton} type={BUTTON_TYPES.tertiarySuccess}>Verification Report</Button>
                                    : isUrl(verificationReportUrl) && <Link data-testid='verification-report-button' className='status__button' href={verificationReportUrl}>View Report</Link>
                            )}
                        </div>
                    </div>
                ),
                [ELIGIBILITY_IMPORT_STATUSES.verification_report_generating]: (
                    <div className='status status_view-report'>
                        <Icon type={ICON_TYPES.checkCircleFull}/>
                        <div className='status__content'>
                            <Button data-testid='validation-report-button' onClick={onClickValidationFileButton} type={BUTTON_TYPES.tertiary} className='status__button'>View Validation Report</Button>
                            <Text className='status_verification-report'>Generating Report</Text>
                        </div>
                    </div>
                ),
                [ELIGIBILITY_IMPORT_STATUSES.cancelled]: (
                    <div className='status status_error'>
                        {additionalData?.canceled_reason
                            ? <Tooltip content={additionalData?.canceled_reason}><Icon type={ICON_TYPES.cancelCircle}/></Tooltip>
                            : <Icon type={ICON_TYPES.cancelCircle}/>}
                        <div className='status__content'>
                            <Text>Canceled</Text>
                            {isNumber(additionalData?.errors_count) && (
                                <Button data-testid='validation-report-button' onClick={onClickValidationFileButton} type={BUTTON_TYPES.tertiary} className='status__button'>View Validation Report</Button>
                            )}
                        </div>
                    </div>
                ),
                [TPA_PARTNER_IMPORT_SESSION_STATUSES.canceled]: (
                    <div className='status status_error'>
                        {additionalData?.canceled_reason
                            ? <Tooltip content={additionalData?.canceled_reason}><Icon type={ICON_TYPES.cancelCircle}/></Tooltip>
                            : <Icon type={ICON_TYPES.cancelCircle}/>}
                        <div className='status__content'>
                            <Text>Canceled</Text>
                            {cell?.row?.original?.preprocessing_report && (
                                <Button data-testid='pre-processing-report-button' onClick={onGoToPreProcessingReport} type={BUTTON_TYPES.tertiary} className='status__button'>Pre-processing Report</Button>
                            )}
                        </div>
                    </div>
                )
            }[status];

            return contentByStatus || <Text>{toCapitalize(status)}</Text>;
        };

        return [
            {Header: 'Date', accessor: 'created_at', Cell: getDate},
            ...!groupAlias ? [
                {Header: 'Client', accessor: partnerId ? 'additional_data.tpa_partner_name' : 'additional_data.company_name'},
                {Header: 'Alias', accessor: 'company_alias', isSortable: false}
            ] : [],
            {Header: 'Type', accessor: 'additional_data.config_type', Cell: getType, align: 'center'},
            {Header: 'Uploaded By', accessor: 'additional_data.created_by', Cell: getUploadedBy, align: 'center'},
            {Header: 'Status', accessor: 'status', isSortable: false, Cell: getStatus, width: 250}
        ];
    };

    const tableProps = {
        data: importData,
        isFilterable: false,
        className: 'mt-20',
        pageSize: TABLE_PAGE_SIZE,
        columns: getTableColumns(),
        onFetchData: onFetchTableData,
        rowProps,
        isCellTooltip: false,
        tableConfig: {getRowId: row => row.id},
        ...(!partnerId && !groupAlias) && {isSubRowsExpandable: true},
        ...table
    };

    const importsSearchChoices = [
        ...!groupAlias ? [{name: 'Company', type: 'company_alias', loadOptions: loadCompanyAliasOptions}] : [],
        {name: 'Uploaded by', type: 'created_by', loadOptions: loadUploadedByOptions}
    ];

    return (
        <div className='imports-search mt-10'>
            {isTitleVisible && <Heading>Import Log</Heading>}

            {!partnerId && <div className='mt-20'><MultipleChoice choices={importsSearchChoices} onChange={setImportSearch}/></div>}

            <Table {...tableProps}/>
        </div>
    );
};

EligibilityImportsSearch.propTypes = {
    groupAlias: PropTypes.string,
    partnerId: PropTypes.number,
    isTitleVisible: PropTypes.bool,
    setImportsData: PropTypes.func,
    getLastSession: PropTypes.func
};

EligibilityImportsSearch.defaultProps = {
    isTitleVisible: true,
    setImportsData: () => {}
};

export {EligibilityImportsSearch as TestableEligibilityImportsSearch};
export default EligibilityImportsSearch;
