import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Text from '@frontend/ui-kit/Components/Text';
import AddCategoryPopup from './AddCategoryPopup';
import EditCategoryPopup from './EditCategoryPopup';
import Category from './Category';
import withPopup from '../../../../HOC/withPopup';
import {requestIndividualConfigAncillaryInfo} from '../../../../actions/adminPortal';
import useFormState from '../../../../hooks/useFormState';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import {filterUniqArrayValues, isEmpty, toCapitalize} from '../../../../utils';
import './index.scss';

const POPUP_ID = 'ancillaryInfoPopup';

const AncillaryInfo = ({isArchived, openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const {values} = useFormState();
    const {id: importConfigId, ancillary_configs: ancillaryConfigs} = values?.import_config || {};
    const [ancillaryInfo, setAncillaryInfo] = useState({});
    const [categories, setCategories] = useState([]);
    const [inputSearch, setInputSearch] = useState('');
    const [duplicatedValues, setDuplicatedValues] = useState({});
    const [categoriesWithDuplicates, setCategoriesWithDuplicates] = useState([]);

    const getCategoriesWithDuplicates = values => Object.values(values)
        .reduce((acc, field) => [...acc, ...Object.keys(field)], [])
        .filter(filterUniqArrayValues);

    const setAncillaryCategoriesData = async importConfigId => {
        const {ancillaryInfo, duplicatedValues} = await dispatch(requestIndividualConfigAncillaryInfo(importConfigId));

        setAncillaryInfo(ancillaryInfo);
        setCategories(Object.keys(ancillaryInfo));
        setDuplicatedValues(duplicatedValues);
        setCategoriesWithDuplicates(getCategoriesWithDuplicates(duplicatedValues));
    };

    useEffect(() => {
        if (!importConfigId || isArchived) {
            return false;
        }

        setAncillaryCategoriesData(importConfigId);
    }, [dispatch, importConfigId]);

    useEffect(() => {
        if (isArchived) {
            setAncillaryInfo(ancillaryConfigs);
            setCategories(Object.keys(ancillaryConfigs ?? {}));
        }
    }, [ancillaryConfigs]);

    const getFormattedCategory = (category = '') => category.split('_').map(toCapitalize).join(' ');

    const getFilterredCategories = categories => {
        const enhancedInputSearch = inputSearch.replaceAll(' ', '_').toLowerCase();

        return categories.filter(category => category.match(enhancedInputSearch));
    };

    const onSearchCategories = () => {
        const categoriesList = Object.keys(ancillaryInfo);

        if (!inputSearch) {
            setCategories(categoriesList);
        }

        setCategories(isEmpty(inputSearch) ? categoriesList : getFilterredCategories(categoriesList));
    };

    const onSearchCategoriesDebounced = useDebouncedCallback(onSearchCategories);

    useEffect(() => {
        onSearchCategoriesDebounced();
    }, [inputSearch]);

    const onClosePopup = () => {
        closePopup();

        if (isArchived) {
            return false;
        }

        setAncillaryCategoriesData(importConfigId);
    };

    const onOpenPopup = ({category}) => {
        const popupProps = {
            importConfigId,
            getFormattedCategory,
            closePopup: onClosePopup
        };
        const children = category
            ? <EditCategoryPopup {...popupProps} initialValues={ancillaryInfo[category]} duplicatedValues={duplicatedValues} isArchived={isArchived}/>
            : <AddCategoryPopup {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    return (
        <div className='ancillary-info'>
            <Row middle='xs'>
                <Column sm>
                    <Input onChange={({target}) => setInputSearch(target?.value)} value={inputSearch} placeholder='Search by category'/>
                </Column>

                {!isArchived && (
                    <Column constant>
                        <Button onClick={onOpenPopup} type={BUTTON_TYPES.tertiary} iconLeft={<Icon type={ICON_TYPES.add}/>}>
                            Add Ancillary Plan
                        </Button>
                    </Column>
                )}
            </Row>

            <Row rowGap='sm' className='mt-20'>
                {categories.map(category => (
                    <Column sm={4} key={category}>
                        <Category onClick={() => onOpenPopup({category})}>
                            {getFormattedCategory(category)}
                        </Category>

                        {categoriesWithDuplicates.includes(category) && (
                            <Text className='pl-20 mt-5 ancillary-category-duplicates-text ancillary-category-duplicates-text_alert'>
                                <Icon className='ancillary-category-duplicates-text__icon' type={ICON_TYPES.alert}/> Duplicates found in one or more values in this plan
                            </Text>
                        )}
                    </Column>
                ))}
            </Row>
        </div>
    );
};

AncillaryInfo.propTypes = {
    isArchived: PropTypes.bool,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {AncillaryInfo as TestableAncillaryInfo};
export default withPopup(POPUP_ID)(AncillaryInfo);
