import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Column from '@frontend/ui-kit/Components/Column';
import Row from '@frontend/ui-kit/Components/Row';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import MultiSelectInput from '@frontend/ui-kit/Components/MultiSelectInput';
import Input from '@frontend/ui-kit/Components/Input';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import Separator from '@frontend/ui-kit/Components/Separator';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import AncillaryInfo from './AncillaryInfo';
import {Field} from '../../shared/FormComponents';
import {getProfileInfo} from '../../../selectors/general';
import {requestFeatureFlag} from '../../../actions/general';
import {FEATURE_FLAGS, IMPORT_CONFIG_TYPES} from '../../../constants';
import {scrollToComponent, equal} from '../../../utils';
import './index.scss';

const JSON_FIELD_PLACEHOLDER = 'Add JSON values...';

// FYI: sections should be placed in order they rendered on page (Yuri, 22.09.2023)
const getCollapses = (configType, isAncillaryConfig) => ({
    ...(!equal(configType, IMPORT_CONFIG_TYPES.tpa) && {general: 'general'}),
    memberInfo: 'memberInfo',
    corePlanInfo: 'corePlanInfo',
    ...(!equal(configType, IMPORT_CONFIG_TYPES.tpa) && isAncillaryConfig && {ancillaryInfo: 'ancillaryInfo'}),
    relationship: 'relationship',
    conditions: 'conditions',
    ...(!equal(configType, IMPORT_CONFIG_TYPES.tpa) && {xmlConfiguration: 'xmlConfiguration'}),
    cobra: 'cobra',
    ...(equal(configType, IMPORT_CONFIG_TYPES.renewal) && {retainIDs: 'retainIDs'})
});

const ImportGroupConfigContent = ({configType = IMPORT_CONFIG_TYPES.renewal, isArchived}) => {
    const dispatch = useDispatch();
    const {email: userEmail} = useSelector(getProfileInfo);
    const [isAncillaryConfig, setIsAncillaryConfig] = useState(false);
    const collapsesRefs = useRef({});
    const collapses = getCollapses(configType, isAncillaryConfig);
    const [collapseStates, setCollapseStates] = useState({[Object.keys(collapses)[0]]: true});

    useEffect(() => {
        (async () => {
            const {flag: isAncillaryConfig} = await dispatch(requestFeatureFlag(FEATURE_FLAGS.ancillaryConfigsManagement, {userEmail}));

            setIsAncillaryConfig(isAncillaryConfig);
        })();
    }, [dispatch, userEmail]);

    const onClickNextSection = currentCollapse => {
        const currentIndex = Object.keys(collapses).findIndex(collapse => equal(collapse, currentCollapse));
        const nextCollapse = Object.keys(collapses).at(currentIndex + 1);

        setCollapseStates(collapseStates => ({
            ...collapseStates,
            [currentCollapse]: false,
            [nextCollapse]: true
        }));

        scrollToComponent(collapsesRefs.current[nextCollapse], 300, {align: {top: 0, topOffset: 60}});
    };

    const onClickPrevSection = currentCollapse => {
        const currentIndex = Object.keys(collapses).findIndex(collapse => equal(collapse, currentCollapse));
        const prevCollapse = Object.keys(collapses).at(currentIndex - 1);

        setCollapseStates(collapseStates => ({
            ...collapseStates,
            [currentCollapse]: false,
            [prevCollapse]: true
        }));

        scrollToComponent(collapsesRefs.current[prevCollapse], 300, {align: {top: 0, topOffset: 60}});
    };

    const getActionBar = collapseName => {
        const currentIndex = Object.keys(collapses).findIndex(collapseItem => equal(collapseItem, collapseName));
        const hasPrev = currentIndex > 0;
        const hasNext = currentIndex < Object.keys(collapses).length - 1;

        return (
            <div className='import-config-content__collapse__action-bar mt-20'>
                {hasPrev && <Button type={BUTTON_TYPES.secondary} onClick={() => onClickPrevSection(collapseName)}>Previous</Button>}
                {hasNext && <Button className='ml-10' onClick={() => onClickNextSection(collapseName)}>Next</Button>}
            </div>
        );
    };

    const getInitiator = (title, collapse) => {
        const ref = element => { collapsesRefs.current[collapse] = element; };

        return (
            <span ref={ref} className='import-config-content__collapse__initiator'>
                <Heading type={HEADING_TYPES['2']}>{title}</Heading>
            </span>
        );
    };

    const onChangeCollapseState = key => state => setCollapseStates(states => ({...states, [key]: state}));

    const getCollapseProps = collapse => ({
        hasCollapseIcon: true,
        collapseIconClassName: 'collapse-icon',
        className: 'import-config-content__collapse',
        isOpened: collapseStates[collapse],
        onChange: onChangeCollapseState(collapse)
    });

    return (
        <div className='import-config-content'>
            {collapses.general && (
                <ContentSection className='mt-20 pb-10'>
                    <Collapse {...getCollapseProps(collapses.general)}
                        initiator={getInitiator('General Information', collapses.general)}
                        content={(
                            <div className='pt-10'>
                                <Row>
                                    <Column sm={6}>
                                        <Field name='import_config.config_activation_date'>
                                            {props => <DatePicker {...props} placeholder='00-00-0000' label='Config Activation Date' isRequired disabled/>}
                                        </Field>
                                    </Column>
                                    {!equal(configType, IMPORT_CONFIG_TYPES.tpa) && (
                                        <Column sm={6}>
                                            <Field name='import_config.manual_file_pattern'>
                                                {props => (<Input {...props} label='Manual File Pattern' isRequired disabled={isArchived}/>)}
                                            </Field>
                                        </Column>
                                    )}
                                </Row>
                                {getActionBar(collapses.general)}
                            </div>
                        )}/>
                </ContentSection>
            )}

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(collapses.memberInfo)}
                    initiator={getInitiator('Member Information', collapses.memberInfo)}
                    content={(
                        <React.Fragment>
                            <Heading type={HEADING_TYPES['3']}>Details</Heading>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.first_name'>
                                        {props => <MultiSelectInput {...props} label='First Name' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.last_name'>
                                        {props => <MultiSelectInput {...props} label='Last Name' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.id_fields'>
                                        {props => <MultiSelectInput {...props} label='ID Fields' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.ssn'>
                                        {props => <MultiSelectInput {...props} label='SSN' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.birthday'>
                                        {props => <MultiSelectInput {...props} label='Birthday' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.gender'>
                                        {props => <MultiSelectInput {...props} label='Gender' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                {!equal(configType, IMPORT_CONFIG_TYPES.launch) && (
                                    <Column sm={6}>
                                        <Field name='import_config.start_date'>
                                            {props => <MultiSelectInput {...props} label='Start Date' disabled={isArchived}/>}
                                        </Field>
                                    </Column>
                                )}
                                <Column sm={6}>
                                    <Field name='import_config.termination_date'>
                                        {props => <MultiSelectInput {...props} label='Termination Date' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Heading type={HEADING_TYPES['3']}>Contact</Heading>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.email_fields'>
                                        {props => <MultiSelectInput {...props} label='Email Fields' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.phone_fields'>
                                        {props => <MultiSelectInput {...props} label='Phone Fields' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.street_address'>
                                        {props => <MultiSelectInput {...props} label='Street Address' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.street_address_2'>
                                        {props => <MultiSelectInput {...props} label='Street Address 2' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.city'>
                                        {props => <MultiSelectInput {...props} label='City' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.state'>
                                        {props => <MultiSelectInput {...props} label='State' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.zip'>
                                        {props => <MultiSelectInput {...props} label='Zip' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Heading type={HEADING_TYPES['3']}>Segmentation</Heading>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.division_name'>
                                        {props => <MultiSelectInput {...props} label='Division Name' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.location'>
                                        {props => <MultiSelectInput {...props} label='Location' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.network'>
                                        {props => <MultiSelectInput {...props} label='Network' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.custom_fields'>
                                        {props => <MultiSelectInput {...props} label='Custom Fields' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            {getActionBar(collapses.memberInfo)}
                        </React.Fragment>
                    )}/>
            </ContentSection>

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(collapses.corePlanInfo)}
                    initiator={getInitiator('Core Plan Information', collapses.corePlanInfo)}
                    content={(
                        <div className='pt-10'>
                            <Heading type={HEADING_TYPES['3']}>Medical</Heading>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.medical_insurance_plan_id'>
                                        {props => <MultiSelectInput {...props} label='Medical Insurance Plan ID' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.medical_member_id'>
                                        {props => <MultiSelectInput {...props} label='Medical Member ID' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.medical_plan_effective_date'>
                                        {props => <MultiSelectInput {...props} label='Medical Plan Effective Date' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.medical_plan_expiration_date'>
                                        {props => <MultiSelectInput {...props} label='Medical Plan Expiration Date' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Heading type={HEADING_TYPES['3']}>Dental</Heading>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.dental_insurance_plan_id'>
                                        {props => <MultiSelectInput {...props} label='Dental Insurance Plan ID' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.dental_member_id'>
                                        {props => <MultiSelectInput {...props} label='Dental Member ID' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.dental_plan_effective_date'>
                                        {props => <MultiSelectInput {...props} label='Dental Plan Effective Date' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.dental_plan_expiration_date'>
                                        {props => <MultiSelectInput {...props} label='Dental Plan Expiration Date' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Heading type={HEADING_TYPES['3']}>Vision</Heading>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.vision_insurance_plan_id'>
                                        {props => <MultiSelectInput {...props} label='Vision Insurance Plan ID' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.vision_member_id'>
                                        {props => <MultiSelectInput {...props} label='Vision Member ID' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.vision_plan_effective_date'>
                                        {props => <MultiSelectInput {...props} label='Vision Plan Effective Date' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.vision_plan_expiration_date'>
                                        {props => <MultiSelectInput {...props} label='Vision Plan Expiration Date' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            {getActionBar(collapses.corePlanInfo)}
                        </div>
                    )}/>
            </ContentSection>

            {collapses.ancillaryInfo && (
                <ContentSection className='mt-20 pb-10'>
                    <Collapse {...getCollapseProps(collapses.ancillaryInfo)}
                        initiator={getInitiator('Ancillary Information', collapses.ancillaryInfo)}
                        content={(
                            <div className='pt-10'>
                                <AncillaryInfo isArchived={isArchived}/>
                                {getActionBar(collapses.ancillaryInfo)}
                            </div>
                        )}/>
                </ContentSection>
            )}

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(collapses.relationship)}
                    initiator={getInitiator('Relationship', collapses.relationship)}
                    content={(
                        <div className='pt-10'>
                            <Row columnGap='xs'>
                                <Column>
                                    <Field name='import_config.invite_relatives'>
                                        {props => <Checkbox {...props} caption='Invite Relatives' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column>
                                    <Field name='import_config.inherit_core_plans'>
                                        {props => <Checkbox {...props} caption='Inherit Core Plans' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column>
                                    <Field name='import_config.inherit_member_ids_for_dependents'>
                                        {props => <Checkbox {...props} caption='Inherit Member IDs for Dependents' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column>
                                    <Field name='import_config.inherit_ancillary_plans'>
                                        {props => <Checkbox {...props} caption='Inherit Ancillary Plans' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.relationship_field'>
                                        {props => <MultiSelectInput {...props} label='Relationship Field' isRequired disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.relationship_mapper'>
                                        {props => <Textarea {...props} label='Relationship Mapper' icon={ICON_TYPES.caseSensitive} tooltipProps={{content: 'Field is case sensitive'}} description='Example: {"value_from file": "relationship_in_HJ"}' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.inherit_core_condition'>
                                        {props => <Textarea {...props} label='Inherit Core Condition' description='Example: [{"key1": "Value 1"}, {"key1": "Value 2"}]' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            {getActionBar(collapses.relationship)}
                        </div>
                    )}/>
            </ContentSection>

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(collapses.conditions)}
                    initiator={getInitiator('Conditions', collapses.conditions)}
                    content={(
                        <div className='pt-10'>
                            <Heading type={HEADING_TYPES['3']}>Allowable Conditions</Heading>

                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.allowable_condition'>
                                        {props => <Textarea {...props} label='Allowable Condition' icon={ICON_TYPES.info} tooltipProps={{content: 'A single column can be listed here. Users with a populated value for that column will be imported.'}} description='Example: {"column_name_from_file" : "value"}' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.not_allowable_condition'>
                                        {props => <Textarea {...props} label='Not Allowable Condition' icon={ICON_TYPES.info} tooltipProps={{content: 'Multiple fields can be listed. Users who have a value associated with 1 or more of these fields will NOT be imported.'}} description='Example: [{"key1": "Value 1"}, {"key1": "Value 2"}]' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.allowable_condition_or'>
                                        {props => <Textarea {...props} label='Allowable Condition Or' icon={ICON_TYPES.info} tooltipProps={{content: 'Multiple fields can be listed. Users must have a value associated with 1 or more of these fields in order to be imported.'}} description='Example: [{"key1": "Value 1"}, {"key1": "Value 2"}]' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Heading type={HEADING_TYPES['3']}>Empty Fields</Heading>
                            <Row columnGap='xs' className='mt-10'>
                                <Column>
                                    <Field name='import_config.delete_ancillary_plans_if_empty'>
                                        {props => <Checkbox {...props} caption='Delete Ancillary Plans if Empty' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column>
                                    <Field name='import_config.delete_plans_if_empty'>
                                        {props => <Checkbox {...props} caption='Delete Plans if Empty' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column>
                                    <Field name='import_config.terminate_if_not_in_file'>
                                        {props => <Checkbox {...props} caption='Terminate if Not in File' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.empty_fields'>
                                        {props => <MultiSelectInput {...props} label='Empty Fields' icon={ICON_TYPES.info} tooltipProps={{content: 'A single column can be listed here. Users must have and empty value associated with the column in order to be imported.'}} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.not_empty_fields'>
                                        {props => <MultiSelectInput {...props} label='Not Empty Fields' icon={ICON_TYPES.info} tooltipProps={{content: 'A single column can be listed here. Users must have a populated value for that column in order to be imported.'}} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.empty_fields_or'>
                                        {props => <MultiSelectInput {...props} label='Empty Fields Or' icon={ICON_TYPES.info} tooltipProps={{content: 'Multiple fields can be listed. Users must have an empty value associated at least 1 of the listed field in order to be imported.'}} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.not_empty_fields_or'>
                                        {props => <MultiSelectInput {...props} label='Not Empty Fields Or' icon={ICON_TYPES.info} tooltipProps={{content: 'Multiple fields can be listed. Users must have a value associated with at least 1 of the listed fields in order to be imported.'}} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Heading type={HEADING_TYPES['3']}>Modifiers</Heading>
                            <Row className='mt-10' rowGap='md'>
                                <Column sm={6}>
                                    <Field name='import_config.ignore_termination_date_condition'>
                                        {props => <Textarea {...props} label='Ignore Termination Date Condition' description='Example: [{"key1": "Value 1"}, {"key1": "Value 2"}]' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.ignore_fields'>
                                        {props => <Textarea {...props} label='Ignore Fields' icon={ICON_TYPES.info} tooltipProps={{content: 'Users with fields listed here will NOT be imported.'}} description='Example: {"key1": "Value 2"}' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.ignore_members'>
                                        {props => <Textarea {...props} label='Ignore Members' icon={ICON_TYPES.info} tooltipProps={{content: 'Values listed here will NOT be processed.'}} description='Example: {"column_name_from_file" : "value"}' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            {getActionBar(collapses.conditions)}
                        </div>
                    )}/>
            </ContentSection>

            {collapses.xmlConfiguration && (
                <ContentSection className='mt-20 pb-10'>
                    <Collapse {...getCollapseProps(collapses.xmlConfiguration)}
                        initiator={getInitiator('XML Configurations', collapses.xmlConfiguration)}
                        content={(
                            <div className='pt-10'>
                                <Row className='mt-10'>
                                    <Column sm={6}>
                                        <Field name='import_config.key_custom_fields'>
                                            {props => <Textarea {...props} label='Key Custom Fields' description='Example: {"your_column_name":"FieldNameInXml"}' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                        </Field>
                                    </Column>
                                    <Column sm={6}>
                                        <Field name='import_config.xml_mapping'>
                                            {props => <Textarea {...props} label='Xml Mapping' description='Example {"Field Name In Xml":"category_name"}' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                        </Field>
                                    </Column>
                                </Row>
                                <Row className='mt-10'>
                                    <Column sm={6}>
                                        <Field name='import_config.xml_parse_only_fields'>
                                            {props => <Textarea {...props} label='Xml Parse Only Fields' description='Example: {"your_column_name":"FieldNameInXml"}' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                        </Field>
                                    </Column>
                                    <Column sm={6}>
                                        <Field name='import_config.xml_voluntary_custom_mapping'>
                                            {props => <Textarea {...props} label='Xml Voluntary Custom Mapping' description='Example {"Field Name In Xml":"category_name"}' placeholder={JSON_FIELD_PLACEHOLDER} disabled={isArchived}/>}
                                        </Field>
                                    </Column>
                                </Row>

                                {getActionBar(collapses.xmlConfiguration)}
                            </div>
                        )}/>
                </ContentSection>
            )}

            <ContentSection className='mt-20 pb-10'>
                <Collapse {...getCollapseProps(collapses.cobra)}
                    initiator={getInitiator('Cobra', collapses.cobra)}
                    content={(
                        <div className='pt-10'>
                            <Field name='import_config.cobra_in_separate_file'>
                                {props => <Checkbox {...props} caption='COBRA in Separate File' disabled={isArchived}/>}
                            </Field>

                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.cobra_condition'>
                                        {props => <Input {...props} label='COBRA Condition' description='Example: {"key1": "Value 2"}' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.cobra_file_pattern'>
                                        {props => <Input {...props} label='Cobra Manual File Pattern' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Row className='mt-10'>
                                <Column sm={6}>
                                    <Field name='import_config.cobra_start_date'>
                                        {props => <MultiSelectInput {...props} label='COBRA Start Date' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='import_config.cobra_termination_date'>
                                        {props => <MultiSelectInput {...props} label='COBRA Termination Date' disabled={isArchived}/>}
                                    </Field>
                                </Column>
                            </Row>
                            {getActionBar(collapses.cobra)}
                        </div>
                    )}/>
            </ContentSection>

            {collapses.retainIDs && (
                <ContentSection className='mt-20 pb-10'>
                    <Collapse {...getCollapseProps(collapses.retainIDs)}
                        initiator={getInitiator('Retain IDs', collapses.retainIDs)}
                        content={(
                            <div className='pt-10'>
                                <Row columnGap='xs' className='mt-10'>
                                    <Column>
                                        <Field name='import_config.retain_medical_member_id'>
                                            {props => <Checkbox {...props} caption='Retain Medical Member ID' disabled={isArchived}/>}
                                        </Field>
                                    </Column>
                                    <Column>
                                        <Field name='import_config.retain_dental_member_id'>
                                            {props => <Checkbox {...props} caption='Retain Dental Member ID' disabled={isArchived}/>}
                                        </Field>
                                    </Column>
                                    <Column>
                                        <Field name='import_config.retain_vision_member_id'>
                                            {props => <Checkbox {...props} caption='Retain Vision Member ID' disabled={isArchived}/>}
                                        </Field>
                                    </Column>
                                </Row>

                                {getActionBar(collapses.retainIDs)}
                            </div>
                        )}/>
                </ContentSection>
            )}
        </div>
    );
};

ImportGroupConfigContent.propTypes = {
    configType: PropTypes.string,
    isArchived: PropTypes.bool
};

export {ImportGroupConfigContent as TestableImportGroupConfigContent};
export default ImportGroupConfigContent;
